<script setup lang="ts">
import { PropType } from 'vue'
import { ContentType } from '@/models/content'
import ProtectedLink from '@/components/common/ProtectedLink.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import IconPlay from '@/assets/icons/play.svg'
import { titleCase } from '@/utils/string'

const props = defineProps({
  contentType: {
    type: String as PropType<ContentType>,
    default: 'article',
    validator(value: string) {
      return ['article', 'chart', 'video'].includes(value)
    },
  },
  slug: {
    type: [String, Number],
    required: true,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  coverUrl: {
    type: String,
    required: true,
  },
  isProtected: {
    type: Boolean,
    default: false,
  },
})

const { $cloudinary } = useNuxtApp()

const formattedDuration = useFormattedDuration(
  props.contentType,
  props.duration,
)

const isVideo = computed(() => props.contentType === 'video')
const href = useContentHref(props.contentType, props.slug)
const coverPosition = isVideo.value
  ? 'object-left'
  : useCoverPosition(props.coverUrl)

const coverUrlResized = isVideo.value
  ? $cloudinary.resize(props.coverUrl, 'vertical-video-card-thumbnail')
  : $cloudinary.resize(props.coverUrl, 'horizontal-card-thumbnail')
</script>

<template>
  <component
    :is="isProtected ? ProtectedLink : BaseLink"
    class="card-hover flex pb-4 pt-2 sm:pr-4 lg:items-center lg:pt-4"
    :href="href"
    variant="unstyled"
  >
    <article class="flex w-full flex-col items-start gap-x-3 lg:flex-row">
      <div
        class="flex w-full flex-col items-start gap-x-3 sm:flex-row sm:items-center lg:items-start"
      >
        <div
          class="relative h-0 w-full shrink-0 pb-9/16 sm:h-14 sm:w-25 sm:pb-0 lg:h-28 lg:w-48"
        >
          <img
            height="500"
            width="500"
            :src="coverUrlResized"
            class="absolute h-full min-w-full object-cover"
            :class="coverPosition"
            alt=""
          />

          <div class="absolute top-0 h-full w-full border-2 border-black/10" />

          <div class="absolute right-3 top-3 hidden lg:flex">
            <ContentTypeBadge :type="contentType" />
          </div>

          <div v-if="isVideo">
            <div
              class="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-full text-white opacity-80 lg:hidden"
            >
              <IconPlay class="h-8 w-8" />
            </div>
          </div>

          <!-- Duration badge -->
          <div
            v-if="isVideo"
            class="absolute bottom-0 right-0 p-3 sm:p-0 lg:p-3"
          >
            <div
              class="rounded-3xl bg-black px-2.5 py-1.5 text-xxs font-semibold text-white"
            >
              {{ formattedDuration }}
            </div>
          </div>
        </div>
        <div class="flex items-center pt-2 sm:pt-0">
          <div class="pr-1 lg:hidden">
            <ContentTypeBadge :type="contentType" icon-gray />
          </div>
          <div class="flex sm:flex-col">
            <p class="text-xs font-semibold text-s-500 md:mb-0.5">
              {{ titleCase(contentType) }}
              <span class="sm:hidden">-&nbsp;</span>
            </p>
            <p class="text-xs font-semibold text-s-500">
              {{ publishedDate }}
              <span v-if="!isVideo">· {{ formattedDuration }}</span>
            </p>
            <h3
              class="mt-1 hidden text-lg font-semibold leading-snug md:mt-1 md:text-xl md:font-bold lg:block"
            >
              {{ title }}
            </h3>
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="lg:hidden">
        <h3
          class="mt-2 text-lg font-semibold leading-snug md:mt-1 md:text-xl md:font-bold"
        >
          {{ title }}
        </h3>
      </div>
    </article>
  </component>
</template>
